import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      exibirFiltro: true,
      breadcrumbs: [
        {
          text: this.$tc('title.contrato_fechamento', 1),
          to: 'apuracao-contrato',
          disabled: true,
          nivel: 1,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoContratoNivel3',
    ]),
  },
  watch: {
    filtroApuracaoContratoNivel3(val) {
      if (val && this.breadcrumbs[2]) {
        this.breadcrumbs[2].text = this.montarTextoNivel3(val);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContratoNivel2',
      'setFiltroApuracaoContratoNivel3',
    ]),
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }

      const visaoContrato = this.$router.currentRoute.name === 'listarApuracoesContratoNivel2Contrato'
        || this.$router.currentRoute.name === 'detalharApuracaoContrato'
        || this.$router.currentRoute.name === 'finalizarLoteApuracoesContrato';

      const params = {
        visao: visaoContrato ? 'Contrato' : 'Status',
        origemContrato: visaoContrato ? item.text : '',
      };

      let query;

      if (item.status) {
        params.status = item.status;
      }

      if (item.idContrato) {
        params.idContrato = item.idContrato;
        query = { origemContrato: item.text };
      }

      this.$router.push({ name: item.to, params, query });
    },
    alteracaoRota(to) {
      this.exibirFiltro = true;
      if (to.name === 'listarApuracoesContratoNivel2Status'
        || to.name === 'finalizarLoteApuracoesContratoStatus'
        || to.name === 'finalizarLoteApuracoesContratoIndustriaStatus') {
        this.adicionarItemBreadcrumbsStatus(to);
      } else if (to.name === 'listarApuracoesContratoNivel2Contrato'
        || to.name === 'finalizarLoteApuracoesContrato'
        || to.name === 'finalizarLoteApuracoesContratoIndustria') {
        this.adicionarItemBreadcrumbsContrato(to);
      } else if (to.name === 'apuracao-contrato') {
        this.removerItemBreadcrumbs();
      } else {
        this.exibirFiltro = false;
        this.abrirFiltro = false;
        if (to.name === 'detalharApuracaoContratoStatus') {
          this.adicionarItemBreadcrumbsDetalhesStatus(to);
        } else if (to.name === 'detalharApuracaoContrato') {
          this.adicionarItemBreadcrumbsDetalhesContrato(to);
        }
      }
      if (to.name === 'finalizarLoteApuracoesContratoStatus'
        || to.name === 'finalizarLoteApuracoesContratoIndustriaStatus'
        || to.name === 'finalizarLoteApuracoesContrato'
        || to.name === 'finalizarLoteApuracoesContratoIndustria') {
        this.exibirFiltro = false;
        this.abrirFiltro = false;
      }
    },
    adicionarItemBreadcrumbsStatus(to) {
      this.breadcrumbs[0].disabled = false;
      let texto = this.$tc(`status_entidade.${to.params.status.toLowerCase()}`, 1);
      if (to.name === 'finalizarLoteApuracoesContratoStatus'
        || to.name === 'finalizarLoteApuracoesContratoIndustriaStatus') {
        texto = `${this.$t('title.apuracoes_lote')} - ${texto}`;
      }

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: texto,
          status: to.params.status,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoContratoNivel3(null);
    },
    adicionarItemBreadcrumbsContrato(to) {
      this.breadcrumbs[0].disabled = false;
      let texto = to.query.origemContrato;
      if (to.name === 'finalizarLoteApuracoesContrato'
        || to.name === 'finalizarLoteApuracoesContratoIndustria') {
        texto = `${this.$t('title.apuracoes_lote')} - ${texto}`;
      }
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: texto,
          idContrato: to.params.idContrato,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoContratoNivel3(null);
    },
    adicionarItemBreadcrumbsDetalhesStatus(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: 'listarApuracoesContratoNivel2Status',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesContrato(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.params.origem,
          idContrato: to.params.idContrato,
          to: 'listarApuracoesContratoNivel2Contrato',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    montarTextoNivel3(params) {
      let texto = '';
      if (params.idContrato) {
        texto = texto.concat(params.idContrato).concat(' - ');
      }
      if (params.idAcao) {
        texto = texto.concat(params.idAcao).concat(' - ');
      }
      if (params.codAcao) {
        texto = texto.concat(params.codAcao).concat(' - ');
      }
      if (params.codApuracao) {
        texto = texto.concat(params.codApuracao);
      }
      if (params.id) {
        texto = texto.concat(params.id);
      }
      return texto;
    },
    removerItemBreadcrumbs() {
      this.breadcrumbs[0].disabled = true;
      this.breadcrumbs = [this.breadcrumbs[0]];
      this.setFiltroApuracaoContratoNivel1(null);
      this.setFiltroApuracaoContratoNivel2(null);
      this.setFiltroApuracaoContratoNivel3(null);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.alteracaoRota(to);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.alteracaoRota(to));
  },
};
